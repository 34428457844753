import { GenericErrorPage } from '@components/errors/GenericError.page';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <GenericErrorPage height="h-screen" />,
    children: [
      {
        path: '/',
        lazy: async () => {
          const { DashboardPage } = await import('@pages/dashboard/page');
          return { Component: DashboardPage };
        },
      },
      {
        path: 'orders',
        lazy: async () => {
          const { OrdersPage } = await import('@pages/orders/page');
          return { Component: OrdersPage };
        },
        children: [
          {
            path: ':orderId',
            lazy: async () => {
              const { OrderDetailsPage } = await import(
                '@pages/orders/OrderDetails.page'
              );
              return { Component: OrderDetailsPage };
            },
          },
        ],
      },
      {
        path: 'inventory',
        lazy: async () => {
          const { InventoryPage } = await import('@pages/inventory/page');
          return { Component: InventoryPage };
        },
      },
      {
        path: 'offers',
        lazy: async () => {
          const { OffersPage } = await import('@pages/offers/page');
          return { Component: OffersPage };
        },
        children: [
          {
            path: ':offerGroupPersistentId',
            lazy: async () => {
              const { OfferGroupDetailsPage } = await import(
                '@pages/offers/OfferGroupDetails.page'
              );
              return { Component: OfferGroupDetailsPage };
            },
          },
        ],
      },
      {
        path: 'products',
        lazy: async () => {
          const { ProductsPage } = await import('@pages/products/page');
          return { Component: ProductsPage };
        },
      },
    ],
  },
]);

export default router;
